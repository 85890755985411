import './App.css';

function App() {
  return (
    <div className="relative min-h-screen flex items-center justify-center">
      {/* Background Images */}
      <div className="absolute inset-0 flex flex-col">
        <div className="flex w-full h-1/2">
          <div
            className="w-1/3 bg-cover bg-left"
            style={{ backgroundImage: 'url(/bg-1.jpg)' }}
          ></div>
          <div
            className="w-1/3 bg-cover bg-center"
            style={{ backgroundImage: 'url(/bg-2.jpg)' }}
          ></div>
          <div
            className="w-1/3 bg-cover bg-right"
            style={{ backgroundImage: 'url(/bg-3.jpg)' }}
          ></div>
        </div>
        <div className="flex w-full h-1/2">
          <div
            className="w-1/3 bg-cover bg-left"
            style={{ backgroundImage: 'url(/bg-4.jpg)' }}
          ></div>
          <div
            className="w-1/3 bg-cover bg-center"
            style={{ backgroundImage: 'url(/bg-5.jpg)' }}
          ></div>
          <div
            className="w-1/3 bg-cover bg-right"
            style={{ backgroundImage: 'url(/bg-6.jpg)' }}
          ></div>
        </div>
      </div>

      {/* Purple Overlay */}
      <div className="absolute inset-0 bg-green-950 opacity-80"></div>

      {/* Foreground Content */}
      <div className="relative z-10 text-center text-gold font-serif">
        <img src='/icon.png' className='h-16 mx-auto' />
        <h1 className="text-5xl md:text-7xl font-bold mb-4">Coming Soon</h1>
        <p className="text-xl md:text-2xl">Stay tuned for something amazing!</p>
        {/* Additional paragraph with absolute positioning */}
        <p className="text-md md:text-xl text-right">-SunriseCap68</p>
      </div>
    </div>
  );
}

export default App;
